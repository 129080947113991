import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  store = normalizeRoot(require('../store/index.js'), 'store/index.js')

  // If store is an exported method = classic mode (deprecated)

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../store/catalogs/actions.js'), 'catalogs/actions.js')
  resolveStoreModules(require('../store/catalogs/getters.js'), 'catalogs/getters.js')
  resolveStoreModules(require('../store/catalogs/mutations.js'), 'catalogs/mutations.js')
  resolveStoreModules(require('../store/catalogs/state.js'), 'catalogs/state.js')
  resolveStoreModules(require('../store/chat/actions.js'), 'chat/actions.js')
  resolveStoreModules(require('../store/chat/getters.js'), 'chat/getters.js')
  resolveStoreModules(require('../store/chat/mutations.js'), 'chat/mutations.js')
  resolveStoreModules(require('../store/chat/state.js'), 'chat/state.js')
  resolveStoreModules(require('../store/cms/actions.js'), 'cms/actions.js')
  resolveStoreModules(require('../store/cms/getters.js'), 'cms/getters.js')
  resolveStoreModules(require('../store/cms/mutations.js'), 'cms/mutations.js')
  resolveStoreModules(require('../store/cms/state.js'), 'cms/state.js')
  resolveStoreModules(require('../store/event/actions.js'), 'event/actions.js')
  resolveStoreModules(require('../store/event/getters.js'), 'event/getters.js')
  resolveStoreModules(require('../store/event/mutations.js'), 'event/mutations.js')
  resolveStoreModules(require('../store/event/state.js'), 'event/state.js')
  resolveStoreModules(require('../store/menu/getters.js'), 'menu/getters.js')
  resolveStoreModules(require('../store/menu/mutations.js'), 'menu/mutations.js')
  resolveStoreModules(require('../store/menu/state.js'), 'menu/state.js')
  resolveStoreModules(require('../store/notifications/actions.js'), 'notifications/actions.js')
  resolveStoreModules(require('../store/notifications/mutations.js'), 'notifications/mutations.js')
  resolveStoreModules(require('../store/notifications/state.js'), 'notifications/state.js')
  resolveStoreModules(require('../store/order/actions.js'), 'order/actions.js')
  resolveStoreModules(require('../store/order/getters.js'), 'order/getters.js')
  resolveStoreModules(require('../store/order/mutations.js'), 'order/mutations.js')
  resolveStoreModules(require('../store/order/state.js'), 'order/state.js')
  resolveStoreModules(require('../store/otp/actions.js'), 'otp/actions.js')
  resolveStoreModules(require('../store/otp/mutations.js'), 'otp/mutations.js')
  resolveStoreModules(require('../store/otp/state.js'), 'otp/state.js')
  resolveStoreModules(require('../store/payment/actions.js'), 'payment/actions.js')
  resolveStoreModules(require('../store/payment/getters.js'), 'payment/getters.js')
  resolveStoreModules(require('../store/payment/mutations.js'), 'payment/mutations.js')
  resolveStoreModules(require('../store/payment/state.js'), 'payment/state.js')
  resolveStoreModules(require('../store/post/actions.js'), 'post/actions.js')
  resolveStoreModules(require('../store/post/getters.js'), 'post/getters.js')
  resolveStoreModules(require('../store/post/mutations.js'), 'post/mutations.js')
  resolveStoreModules(require('../store/post/state.js'), 'post/state.js')
  resolveStoreModules(require('../store/profile/actions.js'), 'profile/actions.js')
  resolveStoreModules(require('../store/profile/getters.js'), 'profile/getters.js')
  resolveStoreModules(require('../store/profile/mutations.js'), 'profile/mutations.js')
  resolveStoreModules(require('../store/profile/state.js'), 'profile/state.js')
  resolveStoreModules(require('../store/register/actions.js'), 'register/actions.js')
  resolveStoreModules(require('../store/register/getters.js'), 'register/getters.js')
  resolveStoreModules(require('../store/register/mutations.js'), 'register/mutations.js')
  resolveStoreModules(require('../store/register/state.js'), 'register/state.js')
  resolveStoreModules(require('../store/service/actions.js'), 'service/actions.js')
  resolveStoreModules(require('../store/service/getters.js'), 'service/getters.js')
  resolveStoreModules(require('../store/service/mutations.js'), 'service/mutations.js')
  resolveStoreModules(require('../store/service/state.js'), 'service/state.js')
  resolveStoreModules(require('../store/settings/actions.js'), 'settings/actions.js')
  resolveStoreModules(require('../store/settings/getters.js'), 'settings/getters.js')
  resolveStoreModules(require('../store/settings/mutations.js'), 'settings/mutations.js')
  resolveStoreModules(require('../store/settings/state.js'), 'settings/state.js')
  resolveStoreModules(require('../store/stream/actions.js'), 'stream/actions.js')
  resolveStoreModules(require('../store/stream/getters.js'), 'stream/getters.js')
  resolveStoreModules(require('../store/stream/mutations.js'), 'stream/mutations.js')
  resolveStoreModules(require('../store/stream/state.js'), 'stream/state.js')
  resolveStoreModules(require('../store/wallet/actions.js'), 'wallet/actions.js')
  resolveStoreModules(require('../store/wallet/getters.js'), 'wallet/getters.js')
  resolveStoreModules(require('../store/wallet/mutations.js'), 'wallet/mutations.js')
  resolveStoreModules(require('../store/wallet/state.js'), 'wallet/state.js')

  // If the environment supports hot reloading...
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const propertyStoreModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(propertyStoreModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
