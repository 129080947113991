import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

export default function usernameValidation({ redirect, route, $auth }) {
  const whiteListPage = ['signup-username', 'services-slug', 'nickname', 'signup-role', 'signup-purpose', 'signup-social', 'logout']
  const username = get($auth, 'user.nickname')
  const strategy = get($auth, 'strategy.name')
  const userType = get($auth, 'user.userType', '')
  const profile = get($auth, 'user.profile', {})

  const socialUrls = [
    'facebookUrl', 'youtubeUrl', 'twitchUrl', 'steamUrl', 'instagramUrl', 'tiktokUrl', 'discordUrl', 'twitterUrl', 'otherUrl',
  ]

  if (strategy === 'custom' || strategy === 'local') {
    if ($auth.loggedIn && isEmpty(username) && !whiteListPage.includes(route.name)) {
      return redirect('/signup/username')
    }
    if ($auth.loggedIn && get(username, 'length', 0) < 4 && !whiteListPage.includes(route.name)) {
      return redirect('/signup/username')
    }
    if ($auth.loggedIn && $auth.user && !$auth.user.userType && !whiteListPage.includes(route.name)) {
      return redirect('/signup/role')
    }
    if ($auth.loggedIn && $auth.user && userType && userType === 'creator' && !whiteListPage.includes(route.name)) {
      const missingSocialUrls = socialUrls.every(url => profile && profile[url] === null)

      if (missingSocialUrls) {
        return redirect('/signup/social')
      }
    }
  }
  return true
}
